import React from 'react';
import Helmet from 'react-helmet';
import '../assets/css/bootstrap.min.css';
import '../assets/scss/now-ui-kit.scss?v=1.4.0';
import '../assets/demo/demo.css?v=1.4.0';
import '../assets/demo/nucleo-icons-page-styles.css?v=1.4.0';

import MainNavbar from '../components/Navbars/MainNavbar.js';
import LandingPageHeader from '../components/Headers/LandingPage/LandingPageHeader.js';
import DefaultFooter from '../components/Footers/DefaultFooter.js';
import { Container, Row, Col } from 'reactstrap';

import CatalogForm from '../components/Forms/CatalogForm';
import ProductPresentation from '../components/Product-presentation';
import { graphql, useStaticQuery } from 'gatsby';
import '../assets/css/bootstrap.min.css';
import '../assets/scss/now-ui-kit.scss?v=1.4.0';
import '../assets/demo/demo.css?v=1.4.0';
import '../assets/demo/nucleo-icons-page-styles.css?v=1.4.0';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  fab,
  faFacebook,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import {
  faCheckSquare,
  faCoffee,
  faArrowRight,
  faArrowLeft,
  faChevronRight,
  faChevronLeft,
  faHandshake,
  faUserTie,
  faAward,
  faHome,
  faPhone,
  faUserCircle,
  faEnvelope,
  faUsers,
  faNewspaper,
  faMedal,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
// import logoImg from './../assets/img/logo_paulo_lourenco_cores_small.png';
import { dom } from '@fortawesome/fontawesome-svg-core';
import BrandsPresentation from '../components/Brands-presentation';
import easImage  from '../assets/img/categories/eas-header.jpg'
dom.watch();

library.add(
  faFacebook,
  faLinkedin,
  fab,
  faArrowRight,
  faArrowLeft,
  faCheckSquare,
  faCoffee,
  faChevronRight,
  faChevronLeft,
  faHandshake,
  faUserTie,
  faAward,
  faHome,
  faPhone,
  faUserCircle,
  faEnvelope,
  faUsers,
  faNewspaper,
  faMedal,
  faInfoCircle
);

export default function Eas() {
  const {
    landing: {
      content: {
        contacts: { phones, addresses, socialLinks },
      },
    },
    eas: { content },
  } = useStaticQuery(pageQuery);

  return (
    <>
      <Helmet
        title={content.seo.titleSeo}
        meta={[
          {
            name: 'description',
            content: content.seo.description,
          },
          {
            name: 'keywords',
            content: content.seo.keywords,
          },
          { name: 'image', content: content.productCategories.list[0].products[0].img },
{ name: 'og:image', content: content.productCategories.list[0].products[0].img }
        ]}
      >
        <html lang='pt' />
      </Helmet>
      <MainNavbar socialLinks={socialLinks} />
      <div className='wrapper' id='top'>
        <LandingPageHeader socialLinks={socialLinks} title={content.title} image={easImage} />
        <Container fluid>
          <div className='section product-presentation'>
            <h2 className='title underline'>{content.title}</h2>
            <Row>
              {/* <Col className='' sm='1'></Col> */}
              <Col className='flex column center pl-3 pt-3' sm='12'>
                <p className='description'>{content.description}</p>
              </Col>
            </Row>
            <Row>
              {content.productCategories.list.map((category) => {
                return (
                  <Col
                    key={category.title + 'EAS'}
                    md='5'
                    sm='10'
                    className='ml-auto mr-auto p-3'
                  >
                    <ProductPresentation
                      items={category.products}
                      title={category.title}
                      description={category.description}
                    ></ProductPresentation>
                  </Col>
                );
              })}
            </Row>
            <Row>
              <Col sm='12' className='ml-auto mr-auto p-3'>
                {/* <hr></hr> */}
                <br />
                <br />
                <h2>{content.brands.title}</h2>
                <BrandsPresentation
                  items={content.brands.list}
                ></BrandsPresentation>
              </Col>
            </Row>
          </div>
        </Container>
        <CatalogForm {...content.catalogForm} category='Eas' />
        <DefaultFooter phones={phones} addresses={addresses} />
      </div>
    </>
  );
}

// export const landingPageQuery = graphql`
//   query {
//     mongodbCloserDevCmsPages(mongodb_id: { eq: "5f8f2295ea3e4769348cabc0" }) {
//       content {
//         contacts {
//           phone
//           address
//           socialLinks {
//             facebook {
//               icon
//               url
//             }
//             linkedin {
//               icon
//               url
//             }
//           }
//         }
//       }
//     }
//   }
// `;

export const pageQuery = graphql`
  query {
    eas: mongodbCloserDevCmsPages(
      mongodb_id: { eq: "60c08776f088ec2b855f0d8b" }
    ) {
      content {
        title
        description
        productCategories {
          title
          list {
            title
            description
            products {
              img
              altText
              title
            }
          }
        }
        brands {
          title
          list {
            img
            altText
            title
          }
        }

        catalogForm {
          title
          emailToSendForm
        }
        seo {
          titleSeo
          description
          keywords
        }
      }
    }
    landing: mongodbCloserDevCmsPages(
      mongodb_id: { eq: "5f8f2295ea3e4769348cabc0" }
    ) {
      content {
        contacts {
          phones
          addresses {
            link
            label
          }
          socialLinks {
            facebook {
              icon
              url
            }
            linkedin {
              icon
              url
            }
          }
        }
      }
    }
  }
`;
